import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";
import Home from "./pages/Home";
import Gallery from "./pages/Gallery";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Submitted from "./pages/Submitted";
import NotFound from "./pages/NotFound";
import ReactGA from "react-ga4";
import Header from './components/Header/Header'

ReactGA.initialize("G-Z0GT624R0X");

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Header /> 
        <Routes>
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/"}`}
            element={<Home />}
            key="Home"
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/home"}`}
            element={<Home />}
            key="Home"
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/about"}`}
            element={<About />}
            key="About"
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/gallery"}`}
            element={<Gallery />}
            key="Gallery"
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            element={<Contact />}
            key="Contact"
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/submitted"}`}
            element={<Submitted />}
            key="Submit"
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;
