import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = (props) => {
  const { pathname } = useLocation(); // Get the current route's pathname

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top whenever pathname changes
  }, [pathname]); // Only trigger when the pathname changes

  return props.children;
};

export default ScrollToTop;
