import React from 'react';
import NavbarItem from './NavbarItem' 
import MobileMenu from "../../mobile-menu/MobileMenu";
import MobileBtn from "../../mobile-menu/MobileBtn";
import { LinkWithQuery } from '../../../helpers/LinkWithQuery';

function Navbar(props) {


return (

<div className="menu_area">	 
    <div className="navigation">
        <div className="container"> 
            <div className="row">
                <div className="col-sm-12">
                
                    {/* Logo */}
                    <div className="header_logo">
                        <LinkWithQuery to={process.env.PUBLIC_URL + "/"}> 
                            <img decoding="async" src="/assets/images/Navy_Seals_Logo.png" 
                                width="160" height="76" className="wp-post-image wp-image-45" title="Navy_Seals_Logo" alt="Navy_Seals_Logo" 
                                sizes="(max-width: 160px) 100vw, 160px"></img>
                        </LinkWithQuery>
                    </div>

                
                {/* Mobile Menu */}

                <MobileBtn /> 

                <MobileMenu />

                {/* End:  Mobile Menu */}

                
                {/* Start:  NAVIGATION  */}
 
                <div id="navigation">
                    <ul>
                        <NavbarItem />
                    </ul>
                </div>

                {/* End: NAVIGATION  */}


                </div>
            </div>
        </div>
    </div>
</div>

    );
}

export default Navbar;