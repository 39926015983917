import React, { Fragment } from "react"; 
import { Helmet } from 'react-helmet';
import LayoutOne from "../layouts/LayoutOne"; 
import PageHeader from '../components/PageHeader'
import SubmittedContent from './SubmittedContent'
import { useLocation } from "react-router-dom";

const SubmittedPage = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const source = searchParams.get("source");

    // Customize the title and content based on the source
    const message = source === "quote"
      ? "We’ve received your request and are excited to help with your move!"
      : "We’ve received your contact request and are here to assist you!";

     // Define custom paragraphs based on source
    const paragraph1 = source === "quote"
     ? "One of our representatives will be in touch shortly to discuss your needs and provide a personalized quote."
     : "Our team is reviewing your request and will get back to you as soon as possible.";

    const paragraph2 = source === "quote"
     ? "We look forward to making your move as smooth and seamless as possible!"
     : "We look forward to connecting with you!";

    return(
        <Fragment>
            <Helmet>
              <title>Navy Seals Moving | Quote Request</title>
              <meta
                name="description"
                content="Navy Seals Moving."
              />
            </Helmet>

            <LayoutOne>
            
                <PageHeader
                    bgImg={(process.env.PUBLIC_URL + '/assets/images/page_bg.jpg')}
                    title="Thank You!" 
                />
                <SubmittedContent 
                    message = {message} 
                    paragraph1={paragraph1}
                    paragraph2={paragraph2}
                /> 
            </LayoutOne>
        </Fragment>
            
    ); 
};

export default SubmittedPage;   