import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom/client"; // Use createRoot from react-dom/client
import App from "./App"; 
import "../node_modules/bootstrap/dist/css/bootstrap.css"; 
import '@fortawesome/fontawesome-free/css/all.min.css';
import "./assets/icon/elegant-icons/style.css"; 
import "./assets/css/normalize.css"; 
import "./assets/css/style.css";   
import "./assets/css/responsive.css";   
 
import * as serviceWorker from "./serviceWorker";

// Get the root DOM element
const rootElement = document.getElementById("root");

// Create a root using createRoot
const root = ReactDOM.createRoot(rootElement);

// Render your app using root.render
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
