import React from 'react';    
import SectionTitle from "../SectionTitles/SectionTitle";
import AboutList from "./AboutList"; 
import AboutData from "../../data/About/about-one";
import AboutDataLocal from "../../data/About/about-one-local";
const currentUrl = window.location.href;
let content
if (currentUrl.includes("local.navysealsmoving")) {
    content = AboutDataLocal
} else {
    content = AboutData
}

const SkillSec = () => {
    return (
    <section className="our-skill section">
        <div className="container">
            {/* Heading */} 
            <SectionTitle 
                title="Our Services" 
            />
            {/* End: Heading */ }
            <div className="row">
                <div className="row about_list_warp"> 

                    {/*Start:   About List */} 
                    {content.map(about=>(
                        <AboutList 
                            key={about.id} 
                            title={about.title} 
                            text={about.text}  
                            icon={about.icon}  
                        />
                    ))}
                    {/*End:  About List*/}

                </div>
            </div>
        </div>
    </section>
    );
};

export default SkillSec;