import React from 'react';
import { useLocation } from 'react-router-dom';

// Mapping of states to their respective subdomains
const subdomainMapping = {
  "Washington": "https://washington.navysealsmoving.com",
  "Oregon": "https://oregon.navysealsmoving.com",
  "New York - New Jersey": "https://nynj.navysealsmoving.com",
  "North Carolina": "https://nc.navysealsmoving.com"
};

const Header = () => {
  const { search } = useLocation();
  const states = Object.keys(subdomainMapping);

  // Dynamically calculate the current subdomain each render
  const currentSubdomain = window.location.hostname.split('.')[0].toLowerCase();

  // Function to check if the current state should be highlighted
  const isHighlighted = (state) => {
    if (currentSubdomain === "local") return false;

    return (
      (state === "Washington" && (currentSubdomain === "washington" || !["oregon", "nynj", "nc"].includes(currentSubdomain))) ||
      (state === "Oregon" && currentSubdomain === "oregon") ||
      (state === "New York - New Jersey" && currentSubdomain === "nynj") ||
      (state === "North Carolina" && currentSubdomain === "nc")
    );
  };

  return (
    <div className="header_top_area">
      <div className="container">
        <div className="row">
          {/* Address / Phone / Email */}
          <div className="colm">
            <div className="header_wrapper_1">
              <ul>
                <li>
                  <i className="icon_mail_alt"></i>
                  <a href="mailto:info@navysealsmoving.com">info@navysealsmoving.com</a>
                </li>
                <li>
                  {states.map((state, index) => (
                    <React.Fragment key={state}>
                      {currentSubdomain === "local" ? (
                        <span
                          style={{
                            textDecoration: "none",
                            marginRight: "10px"
                          }}
                        >
                          {state}
                        </span>
                      ) : (
                        <a
                          href={`${subdomainMapping[state]}${search}`}
                          className={isHighlighted(state) ? "highlighted-link navbar-state" : "navbar-state"}
                          style={{
                            textDecoration: isHighlighted(state) ? "underline" : "none",
                            fontWeight: isHighlighted(state) ? "bold" : "normal",
                            cursor: "pointer",
                            marginRight: "10px"
                          }}
                        >
                          {state}
                        </a>
                      )}
                      {index < states.length - 1 && <span>| </span>}
                    </React.Fragment>
                  ))}
                </li>
              </ul>
            </div>
            <div className="call_social">
              <a href="tel:2066366961">
                <div className="call_us_now">
                  <ul>
                    <li>CALL US NOW</li>
                    <li>
                      <i className="icon_phone"></i> (206) 636-6961
                    </li>
                  </ul>
                </div>
              </a>
              <div className="header_social">
                <a target="_blank" rel="noopener noreferrer" href="https://goo.gl/maps/cUrKYLa1WfEjfajX8">
                  <i className="fab fa-google"></i>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.yelp.ca/biz/navy-seals-moving-tukwila">
                  <i className="fab fa-yelp"></i>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/@navysealsmoving7695/videos">
                  <i className="fab fa-youtube-square"></i>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/profile.php?id=100066631421741">
                  <i className="fab fa-facebook"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
