import React from 'react';
 
const COntactInfo = () => {

    return (

      <div className="row">
        <div className="bottom_contact col-xs-12">
            <i className="icon_pin_alt"></i>
            <p>
              <p>Navy Seals Moving</p>
              <h4>922 Valley Ave NW</h4>
              <h4>Unit 102</h4>
              <h4>Puyallup, WA  98371</h4>
            </p>
        </div>
        <div className="bottom_contact col-xs-12">
            <i className="icon_clock_alt"></i>
            <p>
              <p>Opening Hours</p>
              <h4>Mon-Sat:</h4>
              <h4>9am - 9pm</h4>
              <h4>Sun: </h4>
              <h4>9am - 1pm</h4>
            </p>
        </div>
        <div className="bottom_contact col-xs-12">
        <i className="icon_phone"></i>
          <p>
            <p>Phone</p>
            <h4>(206) 636-6961</h4>
          </p>
        </div>
        <div className="bottom_contact col-xs-12">
            <i className="icon_mail"></i>
            <p>
              <p>Email</p>
              <h4>info@navysealsmoving.com </h4>
            </p>
        </div>
      </div>

    );
}

export default COntactInfo;