/* global gtag */

import React, { Component } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";

class ContactForm extends Component {

  constructor(props) {
    super(props);

    const urlParams = new URLSearchParams(window.location.search);
    const paramPairs = [];
    urlParams.forEach((value, key) => {
      paramPairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    });

    this.state = {
      keyword: paramPairs.join('&'),
      email: '',
      name: '',
      phone: '',
      message: '',
      errors: {},  // Store errors in a single object
      return_msg: '',
      flag: false,
      loading: false
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validateForm = () => {
    const { email, name, phone } = this.state;
    const errors = {};

    if (!name) errors.name = 'Required Field';
    if (!email) errors.email = 'Required Field';
    else if (!/^(.+)@(.+)$/.test(email)) errors.email = 'Enter Valid Email';
    if (!phone) errors.phone = 'Required Field';

    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (!this.validateForm()) {
      this.setState({ return_msg: 'Please Fill All Fields', flag: true });
      return;
    }

    this.setState({ loading: true });
    try {
      const response = await axios.post(process.env.PUBLIC_URL + "/api/submit/contact", this.state);
      console.log(response);

      // Define a callback to navigate after the conversion report
      const conversionCallback = () => {
        this.props.navigate("/submitted?source=contact");
      };

      // Trigger the conversion report with a single gtag call, using the callback for navigation
      gtag('event', 'conversion', {
        'send_to': 'AW-11127267263/yR1BCJzRp5sYEL-_8rkp',
        'event_callback': conversionCallback
      });

      this.setState({
        return_msg: 'The request has been submitted. Thank you for contacting us!',
        flag: true,
        loading: false
      });
      this.props.navigate("/submitted?source=contact");  // Redirect to the submitted page
    } catch (err) {
      console.log(err);
      this.setState({
        return_msg: 'Unfortunately an error has occurred. We are sorry for the inconvenience and appreciate if you can contact us by phone.',
        flag: true,
        loading: false
      });
    }
  };

  render() {
    const { errors, loading, return_msg, flag } = this.state;

    return (
      <div className="contact-form">
        <div id="message">
          {flag && (
            <div className="alert alert-success">
              <strong>{return_msg}</strong>
            </div>
          )}

        </div> 
        <form onSubmit={this.handleSubmit} id="contactForm">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="input-group"> 
                <span id="err">{errors.name}</span>
                <input
                  type="text"
                  name="name"
                  value={this.state.name}
                  onChange={this.handleChange}
                  className="form-control"
                  placeholder="Name:"
                />
              </div>
              <div className="input-group"> 
                <span id="err">{errors.email}</span>
                <input
                  type="text"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                  className="form-control"
                  placeholder="Email:"
                />
              </div>                            
              <div className="input-group"> 
                <span id="err">{errors.phone}</span>
                <input
                  type="text"
                  name="phone"
                  value={this.state.phone}
                  onChange={this.handleChange}
                  className="form-control"
                  placeholder="Phone:"
                />
              </div> 
            </div> 
            <div className="form-group col-md-6 col-sm-12">
              <div className="input-group input_group_contact_full_width"> 
                <textarea
                  name="message"
                  value={this.state.message}
                  onChange={this.handleChange}
                  rows="6"
                  className="form-control"
                  placeholder="Message"
                />
              </div> 
            </div> 
            <div className="submit-button-group col-sm-12">
              <button
                type="submit"
                className="submit-button btn btn-chos"
                disabled={loading}
              >
                {loading ? "Submitting..." : "Submit"}
              </button>
              <div id="simple-msg"></div>
            </div>
          </div> 
        </form> 
      </div>
    );
  }
}

// Wrap the component with `useNavigate`
export default function ContactFormWrapper() {
  const navigate = useNavigate();
  return <ContactForm navigate={navigate} />;
}
