import React from 'react';   
import SectionTitle from "../components/SectionTitles/SectionTitle";
 

const SubmittedContent = ({ message, paragraph1, paragraph2 }) => {

  return (
 
	<section className="about-section" id="about">
        <div className="container"> 
            <div className="row">
                <div className="col-md-6 col-sm-12 about_bottom_warp">
                    <div className="submitted_list">
                        {/* Start: Heading */}
                        <SectionTitle 
                        title={message}  
                        />
                        <p>{paragraph1}</p>  {/* Dynamic content based on source */}
                        <p>{paragraph2}</p>  {/* Dynamic content based on source */}
                     </div>
                </div>

                {  /*Start: About Image  */} 
                <div className="col-md-6 col-sm-12"> 

                    { /*  About Right Image  */}
                    <img src={process.env.PUBLIC_URL + '/assets/images/submitted.jpg'} alt="" />

                </div> 
                {/*End: About Image  */}
            

            </div>
            {/* row */}
        </div>
        {/* container */}
    </section>


  );
};

export default SubmittedContent;
