import React, { useState } from 'react';
import ReactPlayer from 'react-player/lazy';

const GalleryContent = ({ classes }) => {
    // State to track whether each video is playing
    const [playing, setPlaying] = useState({});

    // Function to handle play on click
    const handlePlay = (key) => {
        setPlaying((prev) => ({ ...prev, [key]: true }));
    };

    return (
        <section className={`work-section ${classes}`} id="work">
            <div className="container">
                <div className="projects-list">
                    <div className="col-md-4 col-sm-12">
                        <div className="single-project-item">
                            <ReactPlayer
                                url="https://www.youtube.com/watch?v=jStK0oQ4PJg"
                                width="100%"
                                height="280px"
                                controls
                                playing={playing['1']}
                                light={true} // Show thumbnail until clicked
                                onClick={() => handlePlay('1')} // Trigger play on first click
                            />
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="single-project-item">
                            <ReactPlayer
                                url="https://www.youtube.com/watch?v=uTTYbggqDGo"
                                width="100%"
                                height="280px"
                                controls
                                playing={playing['2']}
                                light={true}
                                onClick={() => handlePlay('2')}
                            />
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="single-project-item">
                            <ReactPlayer
                                url="https://www.youtube.com/watch?v=4QC6wVD8xYw"
                                width="100%"
                                height="280px"
                                controls
                                playing={playing['3']}
                                light={true}
                                onClick={() => handlePlay('3')}
                            />
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="single-project-item">
                            <ReactPlayer
                                url="https://www.youtube.com/watch?v=O3W7nlzg9bo"
                                width="100%"
                                height="280px"
                                controls
                                playing={playing['4']}
                                light={true}
                                onClick={() => handlePlay('4')}
                            />
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="single-project-item">
                            <ReactPlayer
                                url="https://www.youtube.com/watch?v=JBMvX93wAwA"
                                width="100%"
                                height="280px"
                                controls
                                playing={playing['5']}
                                light={true}
                                onClick={() => handlePlay('5')}
                            />
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="single-project-item">
                            <ReactPlayer
                                url="https://www.youtube.com/watch?v=dBONNW1aZw0"
                                width="100%"
                                height="280px"
                                controls
                                playing={playing['6']}
                                light={true}
                                onClick={() => handlePlay('6')}
                            />
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="single-project-item">
                            <ReactPlayer
                                url="https://www.youtube.com/watch?v=aBM6sUPfL_A"
                                width="100%"
                                height="280px"
                                controls
                                playing={playing['7']}
                                light={true}
                                onClick={() => handlePlay('7')}
                            />
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="single-project-item">
                            <ReactPlayer
                                url="https://www.youtube.com/watch?v=c_p4wUAGnII"
                                width="100%"
                                height="280px"
                                controls
                                playing={playing['8']}
                                light={true}
                                onClick={() => handlePlay('8')}
                            />
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="single-project-item">
                            <ReactPlayer
                                url="https://www.youtube.com/watch?v=2dhS5jkWE18"
                                width="100%"
                                height="280px"
                                controls
                                playing={playing['9']}
                                light={true}
                                onClick={() => handlePlay('9')}
                            />
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="single-project-item">
                            <ReactPlayer
                                url="https://www.youtube.com/watch?v=FSChA-P9W6E"
                                width="100%"
                                height="280px"
                                controls
                                playing={playing['10']}
                                light={true}
                                onClick={() => handlePlay('10')}
                            />
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="single-project-item">
                            <ReactPlayer
                                url="https://www.youtube.com/watch?v=0ssMJzgM17M"
                                width="100%"
                                height="280px"
                                controls
                                playing={playing['11']}
                                light={true}
                                onClick={() => handlePlay('11')}
                            />
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="single-project-item">
                            <ReactPlayer
                                url="https://www.youtube.com/watch?v=65EkZJ3BTw8"
                                width="100%"
                                height="280px"
                                controls
                                playing={playing['12']}
                                light={true}
                                onClick={() => handlePlay('12')}
                            />
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="single-project-item">
                            <ReactPlayer
                                url="https://www.youtube.com/watch?v=08bi6GOdI40"
                                width="100%"
                                height="280px"
                                controls
                                playing={playing['13']}
                                light={true}
                                onClick={() => handlePlay('13')}
                            />
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="single-project-item">
                            <ReactPlayer
                                url="https://www.youtube.com/watch?v=ND4wKnod-4E"
                                width="100%"
                                height="280px"
                                controls
                                playing={playing['14']}
                                light={true}
                                onClick={() => handlePlay('14')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GalleryContent;
