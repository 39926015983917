import React, { useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { Link } from "react-router-dom";
import SectionTitle from "../SectionTitles/SectionTitle";

const GalleryContentShort = ({ classes }) => {
    // State to track whether each video is playing
    const [playing, setPlaying] = useState({});

    // Function to handle play on click
    const handlePlay = (key) => {
        setPlaying((prev) => ({ ...prev, [key]: true }));
    };

    return (
        <section className={`mini-gallery-section ${classes}`} id="work">
            <div className="container">
                <div className="row">
                    {/* Heading */}
                    <div className="more-about-us col-md-8 col-sm-12">
                        <SectionTitle title="More About Us" />
                    </div>
                    {/* End: Heading */}

                    <div className="title-wrapper col-md-8 col-sm-12">
                        <div className="team_video_title">
                            <span className="why_chs_icon"><i className="arrow_carrot-down_alt"></i></span>
                            <h5>Our Team And Storage</h5>
                        </div>
                    </div>
                    <div className="row team-video">
                        <div className="col-md-6 col-sm-12">
                            <video
                                src={process.env.PUBLIC_URL + '/assets/videos/sec-video.mp4'}
                                controls={true}
                                type="video/mp4"
                                className="video-item"
                                poster={process.env.PUBLIC_URL + '/assets/images/sec-video-thumb.jpg'}
                            />
                        </div>
                    </div>

                    <div className="title-wrapper col-md-8 col-sm-12">
                        <div className="team_video_title">
                            <span className="why_chs_icon"><i className="arrow_carrot-down_alt"></i></span>
                            <h5>Customer Testimonials</h5>
                        </div>
                    </div>
                    <div className="projects-list">
                        <div className="col-md-4 col-sm-12">
                            <div className="single-project-item">
                                <ReactPlayer
                                    url="https://www.youtube.com/watch?v=jStK0oQ4PJg"
                                    width="100%"
                                    height="280px"
                                    controls
                                    playing={playing['1']}
                                    light={true} // Show thumbnail until clicked
                                    onClick={() => handlePlay('1')} // Trigger play on first click
                                />
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="single-project-item">
                                <ReactPlayer
                                    url="https://www.youtube.com/watch?v=uTTYbggqDGo"
                                    width="100%"
                                    height="280px"
                                    controls
                                    playing={playing['2']}
                                    light={true}
                                    onClick={() => handlePlay('2')}
                                />
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="single-project-item">
                                <ReactPlayer
                                    url="https://www.youtube.com/watch?v=4QC6wVD8xYw"
                                    width="100%"
                                    height="280px"
                                    controls
                                    playing={playing['3']}
                                    light={true}
                                    onClick={() => handlePlay('3')}
                                />
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="single-project-item">
                                <ReactPlayer
                                    url="https://www.youtube.com/watch?v=O3W7nlzg9bo"
                                    width="100%"
                                    height="280px"
                                    controls
                                    playing={playing['4']}
                                    light={true}
                                    onClick={() => handlePlay('4')}
                                />
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="single-project-item">
                                <ReactPlayer
                                    url="https://www.youtube.com/watch?v=JBMvX93wAwA"
                                    width="100%"
                                    height="280px"
                                    controls
                                    playing={playing['5']}
                                    light={true}
                                    onClick={() => handlePlay('5')}
                                />
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="single-project-item">
                                <ReactPlayer
                                    url="https://www.youtube.com/watch?v=dBONNW1aZw0"
                                    width="100%"
                                    height="280px"
                                    controls
                                    playing={playing['6']}
                                    light={true}
                                    onClick={() => handlePlay('6')}
                                />
                            </div>
                        </div>

                        <div className="slider_btn">
                            <Link to={`${process.env.PUBLIC_URL + "/gallery"}`} className="slider_btn_one">
                                More Testimonials
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GalleryContentShort;
