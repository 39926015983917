import React from 'react';   
import SectionTitle from "../../SectionTitles/SectionTitle";
 

const AboutContent = () => {
    const currentUrl = window.location.href;
    let paragraph1, paragraph2
    if (currentUrl.includes("local.navysealsmoving")) {
        paragraph1 = "Navy Seals Moving is a family-owned company with over 20 years of experience, trusted for both local and long-distance moves across the United States. Built on family values and a strong commitment to our community, we deliver a customer-first approach for every move, whether nearby or across the country. We offer a complete range of services to meet the unique needs of each relocation, ensuring that your experience is seamless, efficient, and stress-free.";
        paragraph2 = "Fully insured, bonded, and licensed, Navy Seals Moving operates secure, climate-controlled facilities and handles each move directly with our dedicated team and fleet, ensuring quality and care every step of the way. Each year, we proudly serve nearly 3,000 clients across the Northwest, establishing our reputation as a trusted provider for both local and long-distance moves. From standard moving solutions to our finest white-glove options, we are dedicated to exceeding expectations with every move. With all necessary licenses and credentials, Navy Seals Moving provides the expertise and peace of mind you need—no matter the distance.";
    } else {
        paragraph1 = "Navy Seals Moving is a family-owned company with over 20 years of experience, specializing in long-distance moves across the United States. Our foundation of trust and unity, built on family values, enables us to focus entirely on delivering the best for our clients. We provide a full range of services to meet the unique needs of any long-distance move, and our fully insured, bonded, and licensed company operates out of secure, climate-controlled warehouse facilities. Unlike brokers, we handle your move from start to finish with our own fleet of trucks and skilled team, ensuring seamless service every step of the way. A satisfied customer is at the heart of our mission, and we strive for excellence in every move we make.";
        paragraph2 = "Navy Seals Moving has grown to become one of the premier luxury and standard moving companies in the Northwest. Since our humble beginnings in the early 2000s, we have earned a reputation as one of the most prestigious names in the moving industry. Today, we assist nearly 3,000 clients annually, consistently delivering exceptional results. Whether you choose our standard moving package or our finest white-glove service, we are committed to impressing and exceeding expectations. For your peace of mind, we hold all necessary licenses and credentials, allowing you to trust us with your move confidently.";
    }
  return (
 
	<section className="about-section" id="about">
        <div className="container"> 
            <div className="row">
                <div className="col-md-6 col-sm-12 about_bottom_warp">
                    <div className="about_list">
                        {/* Start: Heading */}
                        <SectionTitle 
                        title="Welcome to Navy Seals Moving "  
                        />
                        <p>{paragraph1}</p>
                        <p>{paragraph2}</p>
                     </div>
                </div>

                {  /*Start: About Image  */} 
                <div className="col-md-6 col-sm-12"> 

                    { /*  About Right Image  */}
                    <img src={process.env.PUBLIC_URL + '/assets/images/about.jpg'} alt="" />

                </div> 
                {/*End: About Image  */}
            

            </div>
            {/* row */}
        </div>
        {/* container */}
    </section>


  );
};

export default AboutContent;
