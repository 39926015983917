import React from "react";
import EstimateForm from '../Estimate/EstimateForm';

const VideoPopup = ({classes}) => {
    return (
        <section className={`video-section ${classes}`} id="video" style={{backgroundImage: `url("${process.env.PUBLIC_URL + '/assets/images/video.jpg' }")`}} >
            <div className="overlay"></div>
            <div className="container" > 
                <div className="row mobile-reverse-order">  
                    <div className="col-md-6 col-sm-12">
                        <div className="video-container">
                            <div className="video-header">
                                <h5>See Us In Action</h5>  
                            </div>
                            <video 
                                src={process.env.PUBLIC_URL + '/assets/videos/main-video.mov'}
                                controls = {true}
                                type="video/quicktime"
                                className="video-item"
                                poster={process.env.PUBLIC_URL + '/assets/images/main-video-thumb.jpg'}
                            />
                        </div>
                    </div> 
                    <div className="col-md-6 col-sm-12">
                        <div className="video-container">
                            <div className="video-header">
                                <h5>Get A Fast And Free Quote</h5>  
                            </div>
                            <EstimateForm />
                        </div>
                    </div> 
                </div> 
            </div> 
        </section>  
   );
};

export default VideoPopup;
