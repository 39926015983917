import React from "react";  


const Titles = () => {
  const currentUrl = window.location.href;
  let locationType = "washington"; // Default washington state

  if (currentUrl.includes("local.navysealsmoving")) {
    locationType = "local";
  } else if (currentUrl.includes("oregon.navysealsmoving")) {
    locationType = "oregon";
  } else if (currentUrl.includes("nynj.navysealsmoving")) {
    locationType = "nynj";
  } else if (currentUrl.includes("nc.navysealsmoving")) {
    locationType = "nc";
  }

  return (
    
	<section 
    className="titles" id="quote" >
	    <div className="container"> 
			<div className="row">  
				<div className="col-md-8 col-sm-12 titles_sec">
					{/* Heading */}
					<div>
					{locationType === "local" && (
						<>
						<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
						<div>  
							<h1>Your Trusted Specialists for Local and Long-Distance Moving</h1>
						</div>
						<div>
							<a target="_blank" rel="noopener noreferrer" href="https://goo.gl/maps/cUrKYLa1WfEjfajX8">
								<img decoding="async" src="/assets/images/google-tag.webp" 
									width="300" title="google_badge" alt="google_badge" />
							</a>
						</div>
						</div>
						</>
					)}
					{locationType === "washington" && (
						<>
						<h1>Your Trusted Long Distance Movers</h1>
						<h2>Nationwide Moving from Washington State to any destination</h2>
						</>
					)}
					{locationType === "oregon" && (
						<>
						<h1>Your Trusted Long Distance Movers</h1>
						<h2>Nationwide Moving from Oregon to any destination</h2>
						</>
					)}
					{locationType === "nynj" && (
						<>
						<h1>Your Trusted Long Distance Movers</h1>
						<h2>Nationwide Moving from New York and New Jersey to any destination</h2>
						</>
					)}
					{locationType === "nc" && (
						<>
						<h1>Your Trusted Long Distance Movers</h1>
						<h2>Nationwide Moving from North Carolina to any destination</h2>
						</>
					)}
					</div>
					{/* End: Heading */}
				</div> 
			</div> 
        </div> 
	</section>  

  );
};

export default Titles;
